import React, { useContext, useRef, useState } from "react";
import PortalReactDOM from 'react-dom'

const ModalContext = React.createContext();

export function ModalProvider({ children }) {
    const modalRef = useRef();
    const [modalContent, setModalContent] = useState(null);
    const [onModalClose, setOnModalClose] = useState(null);
    const [showLogin, setShowLogin] = useState(false)
    const [showSignUp, setShowSignUp] = useState(false)



    const closeModal = () => {
        setModalContent(null);

        if (typeof onModalClose === 'function') {
            setOnModalClose(null);
            onModalClose();
        }
    }

    const contextValue = {
        modalRef,
        modalContent,
        setModalContent,
        setOnModalClose,
        closeModal,
        showLogin,
        setShowLogin,
        showSignUp,
        setShowSignUp
    };

    return (
        <>
            <ModalContext.Provider value={contextValue}>
                {children}
            </ModalContext.Provider>
            <div ref={modalRef} />
        </>
    );
}


export function Modal() {
    const { modalRef, modalContent, closeModal } = useContext(ModalContext)

    if (!modalRef || !modalRef.current || !modalContent) return null;

    return PortalReactDOM.createPortal(
        <div id='modal'>
            <div id="modalBackground" onClick={closeModal} />
            <div id="modal-content">
                {modalContent}
            </div>
        </div>,
        modalRef.current
    )

}


export const useModal = () => useContext(ModalContext)
